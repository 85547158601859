<template>
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-12 col-md-4 order-md-1 order-2">
          <div class="row">
            <div class="col-12 mb-2">
              <div class="row">
                <div class="col-12 col-md-12">
                  <b-input placeholder="Search By Order ID or User Name/Mobile" v-model="search_order_id" />
                </div>
                <div class="col-12 col-md-12 mt-1">
                  <b-form-radio-group v-model="search_assigned" buttons size="sm" class="w-100">
                    <b-form-radio
                      v-for="option in assignedOptsWithCounts"
                      :key="option.text"
                      :value="option.value"
                      :disabled="option.disabled"
                      :button-variant="`outline-${option.buttonVariant}`"
                      :class="{ 'text-underline font-weight-bolder': search_assigned == option.value }"
                    >
                      {{ option.text }} <b-badge :variant="option.buttonVariant" style="margin-left: 6px;">{{ option.badge }}</b-badge>
                    </b-form-radio>
                  </b-form-radio-group>
                </div>
              </div>
            </div>
            <div class="col-12 agent_tracking_sidebar">
              <div class="card" :class="{ 'border-primary': order.open_state }" v-for="(order, ok) in allOrders" :key="`${ok}_${order.id}`">
                <SideBarOrderHeader @vieOrderDetails="vieOrderDetails(order.id)" @open="openOrderDetails(order.id)" :order="order" />
                <b-collapse v-model="order.open_state">
                  <div class="card-body pt-0">
                    <SideBarOrder :order="order" :key="`${currentOrderKey}`" @generateMap="generateMap" />
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-8 order-md-2 order-1">
          <div class="card shadow-lg">
            <div class="row p-1 align-items-center justify-content-between">
              <div class="col-md-auto col-12 mb-md-0 mb-1">
                <span v-if="currentOrderId" class="fs-1rem cp" @click="vieOrderDetails(currentOrder.id)">#{{ currentOrder.order_no }} <small class="text-muted">({{ dateTimeFormat(currentOrder.created_at, 'DD MMM hh:mm A') }})</small></span>
              </div>
              <div class="col-md-auto col-12 mb-md-0 mb-1 d-none d-md-flex">
                  <div class="row justify-content-between align-items-center">
                    <div v-if="currentOrderId" class="col-md-auto col-12 mb-md-0 mb-1">
                      <img title="Clear Order" :src="`${$apiUrl}/images/icons/refresh-48.png`" alt="Refresh" width="30" class="cp" @click="openOrderDetails(currentOrderId)" />
                    </div>
                    <div class="col-md-auto col-12 mb-md-0 mb-1">
                      <b-button variant="outline-success" size="sm">
                        Online: 
                        <b-badge variant="success" style="margin-left: 6px;">{{ online_agents }}</b-badge>
                      </b-button>
                    </div>
                    <div class="col-md-auto col-12 mb-md-0 mb-1">
                      <b-button variant="outline-danger" size="sm">
                        Offline: 
                        <b-badge variant="danger" style="margin-left: 6px;">{{ offline_agents }}</b-badge>
                      </b-button>
                    </div>
                    <div class="col-md-auto col-12 mb-md-0 mb-1">
                      <b-button variant="outline-success" size="sm">
                        Available: 
                        <b-badge variant="success" style="margin-left: 6px;">{{ available_agents }}</b-badge>
                      </b-button>
                    </div>
                    <div class="col-md-auto col-12 mb-md-0 mb-1">
                      <b-button variant="outline-danger" size="sm">
                        Busy: 
                        <b-badge variant="danger" style="margin-left: 6px;">{{ busy_agents }}</b-badge>
                      </b-button>
                    </div>
                  </div>
              </div>
            </div>
            <div v-show="currentOrderId" id="mapboxLeaflet" style="width: 100%; height: 95vh; border-radius: 10px;" />
          </div>
        </div>
      </div>
    </div>
    <b-modal id="viewOrderInfoModal" v-model="showOrderInfo" :cancel-title="$t('Dismiss')" :no-close-on-backdrop="true" :title="$t('Order Details')" size="xl" @hidden="showOrderInfo = false">
      <OrderDetail :is-shop-view="true" :active-order-detail="activeOrderDetail" />
    </b-modal>
  </div>
</template>
<script>
import { isEmpty, dateTimeFormat } from '@/utilities'
import SideBarOrder from '@/components/agents/trackings/SideBarOrder.vue'
import SideBarOrderHeader from '@/components/agents/trackings/SideBarOrderHeader.vue'
import { mapActions, mapGetters } from 'vuex'
import { hideLoader, showLoader } from '@/@core/comp-functions/ui/app'
import OrderDetail from './shared/OrderDetail.vue'

const assignedOpts = [
  { value: null, text: 'All', buttonVariant: 'primary' },
  { value: 'assigned', text: 'Assigned', buttonVariant: 'success' },
  { value: 'unassigned', text: 'UnAssigned', buttonVariant: 'danger' },
]
const assignedStatuses = [
  'ASSIGNED', 'REACHED', 'PICKEDUP', 'ARRIVED', 'DELIVERED', 'COMPLETED', 'OUT_FOR_DELIVERY', 'STARTED'
];
const unAssignedStatuses = [
  'PROCESSING', 'ACCEPTED'
];
export default {
  name: 'AgentTracking',
  components: {
    SideBarOrder,
    SideBarOrderHeader,
    OrderDetail,
  },

  props: {},

  data: () => ({
    assignedOpts,
    showOrderInfo: false,
    activeOrderDetail: {},
    search_order_id: null,
    search_assigned: null,
    currentOrderId: null,
    currentOrderKey: Number(new Date()),
    mapImages: {
      store: require('@/assets/images/app-icons/store_New.png'),
      house: require('@/assets/images/app-icons/house_New.png'),
    },
  }),

  computed: {
    ...mapGetters('shopAgent', ['orders', 'agentsByOrderId', 'online_agents', 'offline_agents', 'available_agents', 'busy_agents']),
    allOrders: {
      get() {
        let mappedOrders = this.orders.map(i => ({ ...i, open_state: i.id == this.currentOrderId }))
        if (!isEmpty(this.search_order_id)) {
          const search_order_id = this.search_order_id.toLowerCase()
          mappedOrders = mappedOrders.filter(i => {
            if (isEmpty(i.order_no)) return false
            const orderId = i.order_no.toLowerCase()
            const orderNoSearch = orderId.includes(search_order_id)

            let userNameSearch = false,
              userMobileSearch = false
            if (!isEmpty(i.user_details?.name)) {
              const userName = i.user_details.name.toLowerCase()
              userNameSearch = userName.includes(search_order_id)
            }
            if (!isEmpty(i.user_details?.mobile)) {
              const userMobile = i.user_details.mobile.toLowerCase()
              userMobileSearch = userMobile.includes(search_order_id)
            }
            return orderNoSearch || userNameSearch || userMobileSearch
          })
        }
        if (!isEmpty(this.search_assigned)) {
          mappedOrders = mappedOrders.filter(i => {
            if (this.search_assigned == 'assigned') {
              return assignedStatuses.some(as => as == i.status)
            } else if (this.search_assigned == 'unassigned') {
              return unAssignedStatuses.some(as => as == i.status)
            }
            return false;
          })
        }
        return mappedOrders
      },
    },
    currentOrder: {
      get() {
        if (isEmpty(this.currentOrderId)) return {}
        return this.allOrders.find(i => i.id == this.currentOrderId)
      },
    },
    currentAgent: {
      get() {
        if (isEmpty(this.currentOrder)) return {}
        if (isEmpty(this.currentOrder.assigned_agent)) return {}
        return {}
      },
    },
    agents: {
      get() {
        if (isEmpty(this.currentOrder)) return []
        let agents = this.agentsByOrderId(this.currentOrder.id)
        if (isEmpty(this.currentAgent)) return agents
        agents = agents.filter(i => i.id != this.currentAgent.id)
        agents.unshift(this.currentAgent)
        return agents
      },
    },
    orderCount: {
      get(){
        const all = this.orders.length
        const assigned = this.orders.filter(i => assignedStatuses.some(as => as == i.status)).length
        const unAssigned = this.orders.filter(i => unAssignedStatuses.some(as => as == i.status)).length
        return {
          all,
          assigned,
          unAssigned,
        }
      }
    },
    assignedOptsWithCounts: {
      get(){
        return assignedOpts.map(a => {
          if(isEmpty(a.value)){
            return {
              ...a,
              badge: this.orderCount.all,
            }
          }else if(a.value == 'assigned'){
            return {
              ...a,
              badge: this.orderCount.assigned,
            }
          }else if(a.value == 'unassigned'){
            return {
              ...a,
              badge: this.orderCount.unAssigned,
            }
          }
        })
      }
    }
  },

  methods: {
    isEmpty, dateTimeFormat,
    ...mapActions('shopAgent', ['getTrackableOrders', 'getOrderTracking', 'getOrderDetails']),
    async openOrderDetails(order_id) {
      if (this.currentOrderId == order_id) {
        this.currentOrderId = null
        return
      }
      this.currentOrderId = order_id
    },
    getOrderAgents(orderId) {
      showLoader()
      this.getOrderTracking(orderId)
        .then(r => {
          hideLoader()
          setTimeout(() => {
            this.generateMap(orderId)
          }, 500)
          this.currentOrderKey = Number(new Date())
        })
        .catch(e => {
          hideLoader()
        })
    },
    resetOrder(){
      if(isEmpty(this.currentOrderId)){
        if(!isEmpty(this.allOrders)){
          const firstOrder = this.allOrders.find((i, idx) => idx === 0)
          if(!isEmpty(firstOrder)){
            this.openOrderDetails(firstOrder.id)
          }
        }
      }
    },
    generateMap() {
      if (window.agentsTracking) {
        window.agentsTracking = null
      }
      if (this.mapbox) {
        this.mapbox.remove()
      }
      this.mapbox = null
      const order = this.currentOrder
      if (isEmpty(order)) return
      if (isEmpty(order.pickup_address)) return
      if (isEmpty(order.delivery_address)) return

      const pickupAddress = order.pickup_address
      const deliveryAddress = order.delivery_address
      const assignedAgent = order.assigned_agent
      const urlRoutes = []
      if (!isEmpty(pickupAddress)) urlRoutes.push(`${pickupAddress.latitude}, ${pickupAddress.longitude}`)
      if (!isEmpty(deliveryAddress)) urlRoutes.push(`${deliveryAddress.latitude}, ${deliveryAddress.longitude}`)
      if (!isEmpty(assignedAgent.latitude)) urlRoutes.push(`${assignedAgent.latitude}, ${assignedAgent.longitude}`)
      if (!this.mapbox) {
        this.mapbox = window.L.map('mapboxLeaflet').setView([-41.2858, 174.78682], 6)
        const mapLink = '<a href="http://openstreetmap.org">OpenStreetMap</a>'
        window.L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: `Map data &copy; ${mapLink}`,
          maxZoom: 18,
        }).addTo(this.mapbox)
      }
      if (urlRoutes.length >= 2)
        window.SuperApp.actions.createRouting(urlRoutes, [this.mapImages.store, this.mapImages.house, `${this.$apiUrl}${assignedAgent.picture}`], this.mapbox, 'agentsTracking')
      if (isEmpty(assignedAgent.id) && !isEmpty(this.agents)) {
        this.agents.forEach(a => {
          window.SuperApp.actions.addMarker([`${a.latitude}, ${a.longitude}`], [`${this.$apiUrl}${a.picture}`], this.mapbox, 'agentsTracking')
        })
      }
      window.SuperApp.helper.resize()
    },
    vieOrderDetails(id) {
      if(isEmpty(id)) return false;
      showLoader()
      this.getOrderDetails(id).then(r => {
        this.showOrderInfo = true
        this.activeOrderDetail = r
        hideLoader()
      }).catch(e => {
        hideLoader()
      })
    },
  },

  mounted() {
    this.getTrackableOrders().then(r => {
      if (Array.isArray(r.orders)) {
        const firstOrder = r.orders.find((i, idx) => idx === 0)
        if (!isEmpty(firstOrder)) {
          this.currentOrderId = firstOrder.id
        }
      }
    })
  },

  watch: {
    currentOrderId: {
      handler(nv) {
        if (!isEmpty(nv)) {
          this.getOrderAgents(nv)
        }
      },
    },
    search_order_id: {
      handler(nv) {
        if (!isEmpty(nv)) {
          this.currentOrderId = null
        }
      },
    },
    search_assigned: {
      handler() {
        this.currentOrderId = null
        this.resetOrder()
      },
    },
  },
}
</script>
<style>
.agent-assign-btn {
  width: 115px;
}
#mapboxLeaflet .leaflet-routing-container {
  display: none;
}
@media screen and (max-width: 767px) {
  .agent-assign-btn {
    width: 100px;
  }
}
</style>
<style scoped>
.agent_tracking_sidebar{
  max-height: 95vh;
  overflow-y: scroll;
}

</style>