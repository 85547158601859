<template>
  <div>
    <div class="mb-1 fs-md-1rem" v-for="(agent, ak) in agents" :key="`agent_${ak}_${order.id}_${agent.id}`">
      <div class="d-flex justify-content-between align-content-center align-items-center">
        <div class="d-flex justify-content-start align-content-center align-items-center">
          <div class="d-none d-md-inline-block mr-md-1" :style='{
            width: "35px", 
            height: "35px",
            backgroundImage: `url(${$apiUrl}${agent.picture})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            borderRadius: "50%"
          }' />
            
          <div class="d-flex flex-column">
            <div>{{ agent.name }}</div>
            <div>{{ agent.mobile }}</div>
          </div>
        </div>
        <div class="d-flex flex-column">
          <div>In {{ agent.total_duration }} Min</div>
          <div>{{ agent.total_distance }} km</div>
        </div>
        <div class="d-flex justify-content-end align-content-center align-items-center">
          <div>
            <b-button :variant="agent.incomplete_orders == 0 ? 'outline-success' : 'outline-danger' " class="agent-assign-btn" size="sm" @click="assignOrderNow(agent.id)" :disabled="currentAgent.id == agent.id">
              <template v-if="currentAgent.id == agent.id">Assgined</template>
              <template v-else>Assign</template>
              <template v-if="agent.incomplete_orders > 0">
                <b-badge variant="danger" style="margin-left: 6px;">{{ agent.incomplete_orders }}</b-badge>
              </template>
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { hideLoader, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app';
import { isEmpty } from '@/utilities'
import { mapActions, mapGetters } from 'vuex'
import { useStoreUi } from '@/views/store/useStore';

export default {
  name: 'SideBarOrder',
  components: {},

  props: {
    order: Object,
  },

  data: () => ({}),

  computed: {
    ...mapGetters('shopAgent', ['agentsByOrderId']),
    currentAgent: {
      get(){
        if (isEmpty(this.order)) return {}
        if (isEmpty(this.order.assigned_agent)) return {}
        return this.order.assigned_agent
      }
    },
    agents: {
      get() {
        if (isEmpty(this.order)) return []
        let agents = this.agentsByOrderId(this.order.id)
        if(isEmpty(this.currentAgent)) return agents;
        agents = agents.filter(i => i.id != this.currentAgent.id)
        return agents
      },
    },
  },

  methods: {
    isEmpty,
    ...mapActions('shopAgent', [
      'getTrackableOrders',
      'assignOrder',
    ]),
    async assignOrderNow(agentId){
      showLoader()
      const { shopAssignAgentToOrder } = useStoreUi()
      const formData = new FormData()

      formData.append('id', this.order.id)
      formData.append('admin_service', 'ORDER')
      formData.append('agent_id', agentId)
      formData.append('comments', '')

      showLoader()
      shopAssignAgentToOrder(formData)
      .then(({ data }) => {
        if (data.statusCode === '200') {
          showSuccessNotification(this, data.message)
          this.getTrackableOrders(this.order.id).then(rr => {
            hideLoader()
            this.$emit('generateMap')
          }).catch(ee => {
            hideLoader()
          })
        }
      }).catch(e => {
        hideLoader()
      })
    }
  },

  watch: {},

  mounted() {},
}
</script>