var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.agents),function(agent,ak){return _c('div',{key:("agent_" + ak + "_" + (_vm.order.id) + "_" + (agent.id)),staticClass:"mb-1 fs-md-1rem"},[_c('div',{staticClass:"d-flex justify-content-between align-content-center align-items-center"},[_c('div',{staticClass:"d-flex justify-content-start align-content-center align-items-center"},[_c('div',{staticClass:"d-none d-md-inline-block mr-md-1",style:({
          width: "35px", 
          height: "35px",
          backgroundImage: ("url(" + _vm.$apiUrl + (agent.picture) + ")"),
          backgroundPosition: "center",
          backgroundSize: "cover",
          borderRadius: "50%"
        })}),_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_vm._v(_vm._s(agent.name))]),_c('div',[_vm._v(_vm._s(agent.mobile))])])]),_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_vm._v("In "+_vm._s(agent.total_duration)+" Min")]),_c('div',[_vm._v(_vm._s(agent.total_distance)+" km")])]),_c('div',{staticClass:"d-flex justify-content-end align-content-center align-items-center"},[_c('div',[_c('b-button',{staticClass:"agent-assign-btn",attrs:{"variant":agent.incomplete_orders == 0 ? 'outline-success' : 'outline-danger',"size":"sm","disabled":_vm.currentAgent.id == agent.id},on:{"click":function($event){return _vm.assignOrderNow(agent.id)}}},[(_vm.currentAgent.id == agent.id)?[_vm._v("Assgined")]:[_vm._v("Assign")],(agent.incomplete_orders > 0)?[_c('b-badge',{staticStyle:{"margin-left":"6px"},attrs:{"variant":"danger"}},[_vm._v(_vm._s(agent.incomplete_orders))])]:_vm._e()],2)],1)])])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }