<template>
  <div class="cp" @click="$emit('open')">
    <div class="px-1 py-1">
      <!-- <div class="order-header cp" @click="$emit('open')"> -->
      <div class="d-flex justify-content-between mb-1">
        <div class="d-flex justify-content-start align-content-center align-items-center">
          <div class="mr-1">
            <img :src="`${$apiUrl}/images/icons/order.svg`" width="30" />
          </div>
          <div>
            <span :class="{ 'text-primary': orderData.open_state }" class="mb-0 my-0 font-weight-bolder fs-1rem">
              {{ orderData.order_no }}
            </span>
            <small class="text-muted">({{ dateTimeFormat(orderData.created_at, 'DD MMM hh:mm A') }})</small>
          </div>
        </div>
        <div>
          <b-badge :variant="orderData.statusVariant">{{ orderData.statusName }}</b-badge>
        </div>
      </div>
      <div class="d-flex justify-content-between mb-1">
        <div class="d-flex justify-content-start align-content-center align-items-center">
          <div class="mr-1">
            <img :src="`${$apiUrl}/images/icons/user.svg`" width="30" />
          </div>
          <div class="d-flex flex-column">
            <div>{{ orderData.user_details.name }}</div>
            <div>{{ orderData.user_details.mobile }}</div>
          </div>
        </div>
        <div>
          <img :src="`${$apiUrl}/images/icons/info.svg`" @click.stop="$emit('vieOrderDetails')" width="30" />
        </div>
      </div>
      <div class="d-flex justify-content-between mb-1">
        <div class="d-flex justify-content-start align-content-center align-items-center">
          <div class="mr-1">
            <img :src="`${$apiUrl}/images/icons/address.svg`" width="30" />
          </div>
          <div class="d-flex flex-column">
            <div><span class="text-muted">Address:</span> {{ orderData.delivery_address.map_address }}</div>
            <div><span class="text-muted">Flat no:</span> {{ orderData.delivery_address.flat_no }}</div>
            <div><span class="text-muted">Street:</span> {{ orderData.delivery_address.street }}</div>
          </div>
        </div>
      </div>
      <div v-if="!isEmpty(agent.id)" class="d-flex justify-content-between mb-1">
        <div class="d-flex justify-content-start align-content-center align-items-center">
          <div class="d-none d-md-inline-block mr-md-1" :style='{
            width: "35px", 
            height: "35px",
            backgroundImage: `url(${$apiUrl}${agent.picture})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            borderRadius: "50%"
          }' />
          <div class="d-flex flex-column">
            <div>{{ agent.name }}</div>
            <div>{{ agent.mobile }}</div>
          </div>
        </div>
        <div class="d-flex flex-column">
          <div>In {{ agent.total_duration }} Min</div>
          <div>{{ agent.total_distance }} km</div>
        </div>
        <div class="d-flex justify-content-end align-content-center align-items-center">
          <div>
            <b-button :variant="'success'" class="agent-assign-btn" size="sm" disabled>
              <template>Assgined</template>
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="d-flex justify-content-center">
      <img class="position-absolute cp" @click="$emit('open')" :src="`${$apiUrl}/images/icons/dropdown.svg`" width="30" style="bottom: -10px" />
    </div> -->
  </div>
</template>

<script>
import { getStatusBadgeColor, isEmpty, dateTimeFormat } from '@/utilities'
export default {
  name: 'SideBarOrderHeader',
  props: {
    order: Object,
  },
  computed: {
    orderData: {
      get() {
        const orderData = this.order
        orderData.statusName = orderData.status.replaceAll('_', ' ')
        orderData.statusVariant = getStatusBadgeColor(orderData.status)
        return orderData
      },
    },
    agent: {
        get(){
            if(!isEmpty(this.order.assigned_agent)){
                return this.order.assigned_agent
            }
            return null;
        }
    }
  },
  methods: {
    getStatusBadgeColor, isEmpty, dateTimeFormat
  },
}
</script>